import Tippy from '@tippyjs/react';
import React, { CSSProperties, Dispatch, SetStateAction, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, convertMinutesToTimeLabel, getListRowStyles } from '../../../../../helpers/utils';
import { setSelectedItem } from '../../../../../redux/quotationSlice';
import styles from '../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../shared/dnd/types';
import ROEstimateServiceDetails from '../details/ROEstimateServiceDetails';
import HoveredContextMenu from '../HoveredContextMenu';
import WrapperRowService from './WrapperRowService';

interface Props {
  clone?: boolean;
  indicator?: boolean;
  wrapperRef?(node: HTMLLIElement): void;
  ghost?: boolean;
  disableSelection?: boolean;
  disableInteraction?: boolean;
  depth: number;
  handleProps?: object;
  indentationWidth: number;
  item: FlattenedItem;
  style: CSSProperties,
  showDetails: boolean,
  setShowDetails: Dispatch<SetStateAction<boolean>>,
}

const ROEstimateRowService = forwardRef<HTMLDivElement, Props>(
  (
    {
      style, clone, depth, indentationWidth, indicator, item, showDetails, setShowDetails,
      wrapperRef, ghost, disableSelection, disableInteraction,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const copiedItems = useAppSelector(state => state.quotation.copiedItems);
    const selectedItem = useAppSelector(state => state.quotation.selectedItem);
    const selectedItems = useAppSelector(state => state.quotation.selectedItems);
    const itemsToCut = useAppSelector(state => state.quotation.itemsToCut);
    const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
    const dispatch = useAppDispatch();
    const [isItemHoveredId, setIsItemHovered] = useState<string | null>(null);

    const selectTreeItem = () => {
      dispatch(setSelectedItem(item));
    };

    const productPropsWrapper = {
      style, clone, depth, indentationWidth, indicator, ref,
      wrapperRef, ghost, disableSelection, disableInteraction,
    };

    const isEstimateMode = useAppSelector(state => state.quotation.isEstimateMode);

    return (
      <WrapperRowService {...productPropsWrapper} >
        <>
          <div
            onClick={selectTreeItem}
            className={classNames(styles.TreeItem)}
            style={getListRowStyles(style, item, copiedItems, selectedItem, itemsToCut, showDetails, selectedItems) as React.CSSProperties}
            onMouseEnter={() => setIsItemHovered(item.id.toString())}
            onMouseLeave={() => setIsItemHovered(null)}
          >
            <div
              className='flex justify-end'
              style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, depth)}px` }}
            >
            </div>
            <div className='w-14 py-3.5 flex justify-between'>
              <TreeItemIcon
                item={item}
                setShowDetails={setShowDetails}
                showDetails={showDetails}
              />
            </div>
            <div className='flex items-center gap-2 2xl:gap-5 3xl:gap-8'>
              <Tippy content={item.position} placement="right" disabled={item.position !== undefined && item.position.length < 10}>
                <div className='w-20 overflow-clip text-ellipsis text-right pr-1' dir="rtl">{item.position}</div>
              </Tippy>
              <div className="w-16 pl-1"></div>
              <div className='w-11 text-right'>{item.quantity}</div>
              <div className="w-32"></div>
              <div className="w-96 3xl:w-[450px] 4xl:w-[800px] truncate pl-2">{item.service?.description}</div>
              <div className="w-20">{item.service?.serviceWage?.costLabel}</div>
              <Tippy content={t('Service time, per item')} placement="top" >
                <div className="w-12 text-center">{convertMinutesToTimeLabel(Number(item.service?.serviceTime))}</div>
              </Tippy>
            </div>
            <div className='flex items-center justify-between 3xl:grow'>
              <div className="w-16">{item.service?.profitSurcharge} %</div>
              <div className="w-28 pl-1">{item?.service?.serviceComputation?.netSalesPricePieceLabel}</div>
              <div className="w-28 pl-1">{item?.service?.serviceComputation?.totalSalesPriceLabel}</div>
              <div className="w-28 pl-1">{item?.service?.serviceComputation?.totalProfitLabel}</div>
            </div>
            {isItemHoveredId === item.id && (
              <HoveredContextMenu
                item={item}
                isItemHoveredId={isItemHoveredId}
                setIsItemHoveredId={setIsItemHovered}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
              />
            )}
          </div>
          {(showDetails && isEstimateMode) && (
            <ROEstimateServiceDetails
              serviceId={item.service?.id}
              indentationWidth={indentationWidth}
              depth={depth}
            />
          )}
        </>
      </WrapperRowService>
    );
  },
);

export default ROEstimateRowService;
