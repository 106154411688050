import React, { CSSProperties, forwardRef, useState } from 'react';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, getListRowStyles } from '../../../../../helpers/utils';
import { setSelectedItem } from '../../../../../redux/quotationSlice';
import { Handle } from '../../../../shared/dnd/Item';
import styles from '../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../shared/dnd/types';
import WrapperRowGroup from './WrapperRowGroup';

export interface Props {
  childCount?: number;
  onCollapse?(): void;
  collapsed?: boolean;
  item: FlattenedItem;
  handleProps?: object;
  clone?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  indicator?: boolean;
  indentationWidth: number;
  style: CSSProperties,
  wrapperRef?(node: HTMLLIElement): void;
}

const DraggedRowGroup = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount, depth, handleProps, indentationWidth, onCollapse, style, item,
      collapsed, clone, disableInteraction, disableSelection, ghost, indicator, wrapperRef,
    },
    ref,
  ) => {
    const [showDetails, setShowDetails] = useState(false);

    const copiedItems = useAppSelector(state => state.quotation.copiedItems);
    const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
    const selectedItem = useAppSelector(state => state.quotation.selectedItem);
    const itemsToCut = useAppSelector(state => state.quotation.itemsToCut);
    const selectedItems = useAppSelector(state => state.quotation.selectedItems);
    const dispatch = useAppDispatch();

    const selectTreeItem = () => {
      dispatch(setSelectedItem(item));
    };

    const groupPropsWrapper = {
      collapsed, indentationWidth, depth, style, clone, indicator,
      ref, wrapperRef, ghost, disableSelection, disableInteraction,
    };

    return (
      <WrapperRowGroup {...groupPropsWrapper}>
        <>
          <div
            onClick={selectTreeItem}
            className={classNames(styles.TreeItem)}
            style={getListRowStyles(style, item, copiedItems, selectedItem, itemsToCut, showDetails, selectedItems) as React.CSSProperties}
          >
            <div
              className={classNames('flex', onCollapse ? 'justify-between' : 'justify-end')}
              style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, depth)}px` }}
            />
            {item.group?.isProductionUnit && item.group?.productionStatus && (
            <div
              className="absolute z-10 top-0 left-10 w-6 rotate-180 font-medium text-white overflow-hidden whitespace-nowrap h-14 shadow-lg rounded-tr rounded-tl"
              style={{
                writingMode: 'vertical-rl', 
                backgroundColor: item.group.productionStatus.color,
              }}
            >
              <span className='truncate' style={{ marginTop: '0.5rem' }}>
                {item.group?.productionStatus.title}
              </span>
            </div>
            )}
            <div className='w-14 py-3.5 flex justify-between'>
              <TreeItemIcon
                item={item}
                setShowDetails={setShowDetails}
                showDetails={showDetails}
                groupIconModifiers={{
                  representsTemplate: !!item.group?.representsTemplate,
                }}
              />
              <Handle {...handleProps} />
            </div>
            <div className="w-12"></div>
            <div className="w-32"></div>
            <div className="w-96 3xl:w-[450px] 4xl:w-[800px] truncate">{item?.group?.description}</div>
            <div className="w-8">
              {childCount && childCount > 1 ? (
                <span className={styles.Count}>{childCount}</span>
              ) : null}
            </div>
          </div>
        </>
      </WrapperRowGroup>
    );
  },
);

export default DraggedRowGroup;