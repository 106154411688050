import React, { ReactElement, useEffect, useRef, useState } from 'react';

interface Props {
  children: ReactElement,
  nestingLevel: number,
}

export default function AddArrowWrapper(props: Props) {
  const { children, nestingLevel } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const ClonedElementWithMoreProps = React.cloneElement(
    children, 
    { ref: ref },
  );

  const getArrowHeight = (arrowNestingLevel: number) => {
    if (arrowNestingLevel === 1) {
      return 21;
    }
    if (arrowNestingLevel === 2) {
      return 16;
    }
    if (arrowNestingLevel === 3) {
      return 11;
    }

    return 6;
  };

  useEffect(() => {
    if (ref && ref.current) {
      const halfHeight = ref.current.clientHeight / 2;   
      setHeight(halfHeight);
    }
  }, [height]);

  return (
    <>
      { ClonedElementWithMoreProps }
      <div 
        style={ { borderWidth: `${height}px 0 ${height}px ${getArrowHeight(nestingLevel)}px` } }
        className={'w-0 h-0 border-l-cgray-400 rounded-tl rounded-bl border-t-transparent border-b-transparent'}
      ></div>
    </>
  );
}
