import { gql } from '../../../__generated__/gql';

export const CREATE_QUOTATION_SERVICE = gql(`
  mutation createQuotationService(
    $quotation: ID!
    $description: String!
    $order: Int!
    $parent: ID!
    $serviceWage: ID!
    $serviceTime: Int!
    ){
      createQuotationService(
        input: {
          quotation: $quotation,
          description: $description,
          order: $order,
          parent: $parent,
          serviceWage: $serviceWage,
          serviceTime: $serviceTime,
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_SERVICE_NOTES = gql(`
  mutation updateServiceNotes(
    $quotation: ID!,
		$notes: String!,
		$service: ID!,
    ){
      updateServiceNotes(
        input: {
          quotation: $quotation,
          notes: $notes,
          service: $service,
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_SERVICE_DESCRIPTION = gql(`
  mutation updateServiceDescription(
    $quotation: ID!
    $service: ID!
    $description: String!
  ){
    updateServiceDescription(
      input: {
        quotation: $quotation
        service: $service
        description: $description
      }
    ){
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_SERVICES_WAGE = gql(`
  mutation updateServicesWage(
    $serviceWage: ID!
    $services: [ID!]!
    $quotation: ID!
    ){
      updateServicesWage(
        input: {
          serviceWage: $serviceWage
          services: $services
          quotation: $quotation
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_SERVICES_TIME = gql(`
  mutation updateServicesTime(
    $serviceTime: Int!
    $services: [ID!]!
    $quotation: ID!
    ){
      updateServicesTime(
        input: {
          serviceTime: $serviceTime
          services: $services
          quotation: $quotation
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_SERVICES_PROFIT_SURCHARGE = gql(`
  mutation updateServicesProfitSurcharge(
    $services: [ID!]!
    $profitSurcharge: Decimal!
    $quotation: ID!
  ){
    updateServicesProfitSurcharge(
      input: {
        services: $services
        profitSurcharge: $profitSurcharge
        quotation: $quotation
      }
    ){ 
      response
      errors{
        field
        messages
      }
    }
  }
`);