import { ControlledMenu } from '@szhsin/react-menu';
import Tippy from '@tippyjs/react';
import React, { CSSProperties, Dispatch, SetStateAction, forwardRef, useState, MouseEvent } from 'react';
import { isMacOs } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WageType } from '../../../../../__generated__/graphql';
import { QUOTATION_QUERY } from '../../../../../api/queries/quotations/quotation';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, getListRowStyles } from '../../../../../helpers/utils';
import { setSelectedItem, addToSelection } from '../../../../../redux/quotationSlice';
import { Handle } from '../../../../shared/dnd/Item';
import styles from '../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../shared/dnd/types';
import { PositionInput, QuantityInput } from '../../fields/item';
import {
  ManufacturingTimeInput,
  ManufacturingWageSelect,
  PositionTypeSelect,
  ProfitSurchargeInput,
} from '../../fields/product';
import { DefaultProductDetails } from '../details';
import HoveredContextMenu from '../HoveredContextMenu';
import SelectedItemsContextMenu from '../SelectedItemsContextMenu';
import WrapperRowProduct from './WrapperRowProduct';

interface Props {
  clone?: boolean;
  indicator?: boolean;
  wrapperRef?(node: HTMLLIElement): void;
  ghost?: boolean;
  disableSelection?: boolean;
  disableInteraction?: boolean;
  depth: number;
  handleProps?: object;
  indentationWidth: number;
  item: FlattenedItem;
  style: CSSProperties,
  showDetails: boolean,
  setShowDetails: Dispatch<SetStateAction<boolean>>,
  hasDndContext?: boolean,
}

const DefaultRowProduct = forwardRef<HTMLDivElement, Props>(
  (
    {
      style, clone, depth, indentationWidth, indicator, item, showDetails, setShowDetails,
      wrapperRef, ghost, disableSelection, disableInteraction, handleProps, hasDndContext,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { quotationId } = useParams();
    const copiedItems = useAppSelector(state => state.quotation.copiedItems);
    const selectedItem = useAppSelector(state => state.quotation.selectedItem);
    const selectedItems = useAppSelector(state => state.quotation.selectedItems);
    const itemsToCut = useAppSelector(state => state.quotation.itemsToCut);
    const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
    const dndGroupId = useAppSelector(state => state.quotation.dndGroupId);
    const dispatch = useAppDispatch();
    const [isItemHoveredId, setIsItemHovered] = useState<string | null>(null);
    const [menuProps, setMenuProps] = useState<{
      x: number;
      y: number;
      show: boolean;
    }>({
      x: 0,
      y: 0,
      show: false,
    });

    const selectTreeItem = (e: MouseEvent) => {
      e.stopPropagation();
      
      const isModifierKeyPressed = isMacOs ? e.metaKey : e.ctrlKey;
      
      if (isModifierKeyPressed) {
        dispatch(addToSelection(item));
      } else {
        dispatch(setSelectedItem(item));
      }
      
      setIsItemHovered(null);
    };

    const handleContextMenu = (e: React.MouseEvent) => {
      if (!selectedItems.length && !selectedItem?.id) {
        dispatch(setSelectedItem(item));
      }

      e.preventDefault();
      setMenuProps({
        x: e.clientX,
        y: e.clientY,
        show: true,
      });
    };

    const productPropsWrapper = {
      style, clone, depth, indentationWidth, indicator, ref,
      wrapperRef, ghost, disableSelection, disableInteraction,
    };

    return (
      <WrapperRowProduct {...productPropsWrapper} >
        <>
          <div
            onClick={selectTreeItem}
            onContextMenu={handleContextMenu}
            className={classNames(styles.TreeItem)}
            style={getListRowStyles(style, item, copiedItems, selectedItem, itemsToCut, showDetails, selectedItems) as React.CSSProperties}
            onMouseEnter={() => setIsItemHovered(item.id.toString())}
            onMouseLeave={() => setIsItemHovered(null)}
          >
            <div
              className='flex justify-end'
              style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, depth)}px` }}
            >
            </div>
            <div className='w-14 py-3.5 flex justify-between'>
              <TreeItemIcon
                item={item}
                setShowDetails={setShowDetails}
                showDetails={showDetails}
              />
              {!!dndGroupId && !!hasDndContext && (
                <Handle {...handleProps} />
              )}
            </div>
            <div className='flex items-center gap-2 2xl:gap-5 3xl:gap-8 relative'>
              <PositionInput
                position={item.position}
                id={item.id}
                refetchQueries={
                  [{
                    query: QUOTATION_QUERY,
                    variables: {
                      quotation: quotationId as string,
                    },
                  }]
                }
              />
              <div className="w-16">
                <PositionTypeSelect
                  setIsItemHovered={setIsItemHovered}
                  positionType={item.product?.positionType}
                  id={item.product?.id}
                  refetchQueries={
                    [{
                      query: QUOTATION_QUERY,
                      variables: {
                        quotation: quotationId as string,
                      },
                    }]
                  }
                />
              </div>
              <QuantityInput
                quantity={item.quantity}
                id={item.id}
                refetchQueries={
                  [{
                    query: QUOTATION_QUERY,
                    variables: {
                      quotation: quotationId as string,
                    },
                  }]
                }
              />
              <div className="w-32">{item.product?.externalId}</div>
              <Tippy content={item?.product?.description} disabled={!item?.product?.description || item?.product?.description.length < 60} placement="top">
                <div className="pl-2 w-96 3xl:w-[450px] 4xl:w-[800px] rounded-t truncate">{item?.product?.description}</div>
                
              </Tippy>
              <div className="w-20">
                <ManufacturingWageSelect
                  productId={item?.product?.id as ID}
                  quotationId={quotationId as ID}
                  refetchQueries={[
                    {
                      query: QUOTATION_QUERY,
                      variables: {
                        quotation: quotationId as string,
                      },
                    },
                  ]}
                  manufacturingWage={item?.product?.manufacturingWage as WageType}
                />
              </div>
              <Tippy content={t('Manufacturing time, per item')} placement="top" >
                <div className="w-12 h-10 flex items-center rounded-t bg-cgray-100 hover:bg-cgray-200 relative">
                  <ManufacturingTimeInput
                    productId={item?.product?.id as ID}
                    quotationId={quotationId as ID}
                    refetchQueries={[
                      {
                        query: QUOTATION_QUERY,
                        variables: {
                          quotation: quotationId as string,
                        },
                      },
                    ]}
                    manufacturingTime={item?.product?.manufacturingTime as number}
                  />
                </div>
              </Tippy>
            </div>
            <div className='flex items-center justify-between 3xl:grow'>
              <div className="w-16">
                <ProfitSurchargeInput
                  productId={item?.product?.id as ID}
                  profitSurcharge={item.product?.profitSurcharge}
                  quotationId={quotationId as ID}
                  refetchQueries={[
                    {
                      query: QUOTATION_QUERY,
                      variables: {
                        quotation: quotationId as string,
                      },
                    },
                  ]}
                />
              </div>
              <div className="pl-1 w-16">{item.product?.supplierLabel}</div>
              <div className="pl-1 w-20 ">{item?.product?.productComputation?.netPricePieceLabel}</div>
              <div className="pl-1 w-28">{item?.product?.productComputation?.netSalesPricePieceLabel}</div>
              <div className="pl-1 w-28">{item?.product?.productComputation?.totalSalesPriceLabel}</div>
            </div>
            {isItemHoveredId === item.id && (
              <HoveredContextMenu
                item={item}
                isItemHoveredId={isItemHoveredId}
                setIsItemHoveredId={setIsItemHovered}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
              />
            )}
          </div>
          {showDetails && (
            <DefaultProductDetails
              externalProductId={item?.product?.externalId as ID}
              catalogId={item?.product?.catalogId as ID}
              productId={item.product?.id}
              indentationWidth={indentationWidth}
              depth={depth}
              maximumUncollapsedDepth={maximumQuotationUncollapsedDepth}
            />
          )}
          { (selectedItems.find(i => i.id === item.id) || selectedItem?.id === item.id) && (
            <ControlledMenu
              anchorPoint={{ x: menuProps.x, y: menuProps.y }}
              state={menuProps.show ? 'open' : 'closed'}
              onClose={() => setMenuProps(prev => ({ ...prev, show: false }))}
              portal={true}
              align="start"
              position="auto"
              direction="bottom"
            >
              <SelectedItemsContextMenu />
            </ControlledMenu>
          )}
        </>
      </WrapperRowProduct>
    );
  },
);

export default DefaultRowProduct;
