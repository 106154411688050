import { useMutation } from '@apollo/client';
import { IconArrowDown, IconCopy } from '@tabler/icons-react';
import { useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ItemTypeChoices } from '../../../../__generated__/graphql';
import { COPY_QUOTATION_ITEM, MOVE_QUOTATION_ITEM_MUTATION } from '../../../../api/mutations/quotations/item';
import { QUOTATION_QUERY } from '../../../../api/queries/quotations/quotation';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { discardAlert, setErrorAlert, setSuccessAlert } from '../../../../redux/alertSlice';
import { setIsLoadingUpdate, unsetCopiedItems, unsetItemsToCut } from '../../../../redux/quotationSlice';
import { FlattenedItem } from '../../../shared/dnd/types';

interface Props {
  item: FlattenedItem,
}

const PasteBelowQuotationItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { item } = props;
  const { quotationId } = useParams();
  const copiedItems = useAppSelector(state => state.quotation.copiedItems);
  const itemsToCut = useAppSelector(state => state.quotation.itemsToCut);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [
    copyQuotationItem,
    {
      loading: copyQuotationItemLoading,
      data: copyQuotationItemData,
    },
  ] = useMutation(COPY_QUOTATION_ITEM, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId as string,
      },
    }],
  });

  const [
    moveQuotationItem,
    {
      loading: moveQuotationItemLoading,
      data: moveQuotationItemData,
    },
  ] = useMutation(MOVE_QUOTATION_ITEM_MUTATION, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId as string,
      },
    }],
  });

  const handlePasteTreeItem = () => {      
    if (itemsToCut.length) {
      if (!item?.parentId 
        && !itemsToCut.every(i => i.itemType === ItemTypeChoices.Group)) {
        dispatch(setErrorAlert([t('Only Groups are allowed to be pasted at the root level')]));
        return;
      }
      dispatch(discardAlert());
      moveQuotationItem(
        {
          variables: {
            order: item?.order as number + 1,
            parent: item?.parentId as string,
            items: itemsToCut.map(i => i.id as string),
            quotation: quotationId as string,
          },
          onError: () => {
            dispatch(setIsLoadingUpdate(false));
          },
        },
      );
      return;
    }
    
    if (copiedItems?.length) {
      dispatch(discardAlert());
      copyQuotationItem(
        {
          variables: {
            items: copiedItems.map(i => i.id as string),
            order: item.order as number + 1,
            parent: item.parentId as string,
            quotation: quotationId as string,
          },
          onError: () => {
            dispatch(setIsLoadingUpdate(false));
          },
        },
      );
    }
  };

  useEffect(() => {
    dispatch(setIsLoadingUpdate(copyQuotationItemLoading || moveQuotationItemLoading));
  }, [dispatch, copyQuotationItemLoading, moveQuotationItemLoading]);

  useEffect(() => {
    if (
      (copyQuotationItemData && copyQuotationItemData.copyQuotationItems && copyQuotationItemData.copyQuotationItems.response) 
      || (moveQuotationItemData && moveQuotationItemData.moveQuotationItems && moveQuotationItemData.moveQuotationItems.response)) {
      dispatch(setSuccessAlert(
        [`${t('Item was successfully pasted')}`],
      ));
      dispatch(unsetCopiedItems());
      dispatch(unsetItemsToCut());
    }
  }, [copyQuotationItemData, dispatch, moveQuotationItemData, t]);

  return (
    <div
      onClick={handlePasteTreeItem}
      className="relative w-6 h-6 flex items-center justify-center"
      ref={ref}
    >
      <IconCopy className="h-6 w-6 cursor-pointer hover:bg-cblue-200" />
      <IconArrowDown 
        className="h-3 w-3 absolute bottom-1 right-1 cursor-pointer" 
        stroke={3}
      />
    </div>
  );
});

export default PasteBelowQuotationItem;
