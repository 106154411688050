import { Dialog, Transition } from '@headlessui/react';
import {
  IconBackspace,
  IconCopy,
  IconIdOff,
  IconListDetails,
  IconReplace,
  IconTrash,
  IconX,
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconArrowNarrowRight,
  IconArrowNarrowLeft,
  IconChevronDown,
  IconChevronRight,
  IconGripVertical,
  IconScissors,
  IconChevronsRight,
  IconChevronsDown,
  IconArrowDown,
  IconArrowRight,
} from '@tabler/icons-react';
import { Fragment } from 'react';
import { isMacOs } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { 
  IconGroupBelow, 
  IconGroupInside, 
  IconItemBelow, 
  IconItemInside, 
  IconServiceBelow, 
  IconServiceInside,
  IconG, 
  IconP,
  IconS,
  IconT,
} from '../../../layout/icons';
import { setIsInfoOverlayOpen } from '../../../redux/quotationSlice';

export default function InfoOverlay() {
  const isInfoOverlayOpen = useAppSelector(state => state.quotation.isInfoOverlayOpen);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Transition.Root show={isInfoOverlayOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-40 text-sm" onClose={() => dispatch(setIsInfoOverlayOpen(false))}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-white/10" />

          <div className="fixed w-3/5 inset-y-0 right-0 max-w-full flex pl-8">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 -left-8 flex bg-cgray-200">
                    <button
                      type="button"
                      className="text-cgray-500"
                      onClick={() => dispatch(setIsInfoOverlayOpen(false))}
                    >
                      <IconX className="h-8 w-8" />
                    </button>
                  </div>
                </Transition.Child>
                <div id="styled-scroll" className="grow flex h-full bg-white shadow-xl overflow-y-auto">
                  <div className='grow flex justify-start items-center h-full rounded bg-white' onClick={e => e.stopPropagation()}>
                    <div className='px-8 py-1 w-full h-full flex flex-col border-r-2 border-cgray-300 text-cgray-600'>
                      <h1 className='font-bold'>{t('Icons legend')}</h1>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconG
                            className={'relative w-6 h-6 flex items-center justify-center border-2 rounded cursor-default bg-cblue-200 font-bold text-cblue-500 border-cblue-500 shrink-0'}
                          />
                        </div>
                        <span>
                          {t('Group Item. Can be placed at the root level of Quotation. Any Item type can be placed inside the Group.')}
                        </span>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconG representsTemplate
                            className={'relative w-6 h-6 flex items-center justify-center border-2 rounded cursor-default bg-cblue-200 font-bold text-cblue-500 border-cblue-500 shrink-0'}
                          />
                        </div>
                        <span>
                          {t('This Group represents Group Template.')}
                        </span>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconG
                            className={'relative w-6 h-6 flex items-center justify-center border-2 rounded cursor-default bg-cblue-200 font-bold text-cblue-500 border-cblue-500 shrink-0'}
                          />
                        </div>
                        <span>
                          {t('This Group has Template presets to apply.')}
                        </span>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconP
                            className={'w-6 h-6 flex items-center border-2 justify-center font-bold rounded cursor-default bg-cblue-500 text-white border-cblue-500 shrink-0'} 
                          />
                        </div>
                        <span>
                          {t('Product item. Represents products from ETIM catalogs. Can only be placed inside Groups.')}
                        </span>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconS
                            className={'w-6 h-6 flex items-center border-2 justify-center font-bold rounded cursor-default bg-cblue-500 text-white border-cblue-500 shrink-0'} 
                          />
                        </div>
                        <span>
                          {t('Service Item. Represents on-site services. Can only be placed inside Groups.')}
                        </span>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconGripVertical className='h-5 w-5 cursor-default text-cgray-500' />
                        </div>
                        <span>
                          {t('Items drag handle.')}
                        </span>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconReplace className="h-5 w-5 cursor-default text-cgray-600" />
                        </div>
                        <span>
                          {t('Show suggestions based on Group Templates stored in database.')}
                        </span>
                      </div>
                      <div className='py-2 flex gap-4 items-center'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconT 
                            className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded" 
                          />
                        </div>
                        <span>
                          {t('Create Group Template from selected Group.')}
                        </span>
                      </div>
                    </div>

                    <div className='px-8 py-1 w-full h-full flex flex-col text-cgray-600'>
                      <h1 className='font-bold'>{t('List of all available keyboard shortcuts')}</h1>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconChevronsRight className=' font-bold text-cblue-500' />
                        </div>
                        <span>{t('Collapse all groups')}</span>
                        <div className='flex items-center gap-1 ml-auto'>
                          <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Shift</span>
                          <span>{' + '}</span>
                          <div className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>c</div>
                        </div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconChevronsDown className=' font-bold text-cblue-500' />
                        </div>
                        <span>{t('Expand all groups')}</span>
                        <div className='flex items-center gap-1 ml-auto'>
                          <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Shift</span>
                          <span>{' + '}</span>
                          <div className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>e</div>
                        </div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconChevronDown className=' font-bold text-cblue-500' />
                        </div>
                        <span>{t('Collapse Group')}</span>
                        <div className='flex items-center gap-1 ml-auto'>
                          <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Shift</span>
                          <span>{' + '}</span>
                          <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100'><IconArrowNarrowLeft /></div>
                        </div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconChevronRight className=' font-bold text-cblue-500' />
                        </div>
                        <span>{t('Uncollapse Group')}</span>
                        <div className='flex items-center gap-1 ml-auto'>
                          <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Shift</span>
                          <span>{' + '}</span>
                          <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100'><IconArrowNarrowRight /></div>
                        </div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconArrowNarrowDown />
                        </div>
                        <span>{t('Move down')}</span>
                        <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100 ml-auto'><IconArrowNarrowDown /></div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconArrowNarrowUp />
                        </div>
                        <span>{t('Move up')}</span>
                        <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100 ml-auto'><IconArrowNarrowUp /></div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconListDetails />
                        </div>
                        <span>{t('Show details')}</span>
                        <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100 ml-auto'><IconArrowNarrowRight /></div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconIdOff />
                        </div>
                        <span>{t('Close details')}</span>
                        <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100 ml-auto'><IconArrowNarrowLeft /></div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconGroupBelow 
                            className='h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded' 
                          />
                        </div>
                        <span>{t('Create Group Below')}</span>
                        <div className='flex items-center gap-1 ml-auto'>
                          <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>G</span>
                          <span>{' + '}</span>
                          <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100'><IconArrowNarrowDown /></div>
                        </div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconGroupInside 
                            className='h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded' 
                          />
                        </div>
                        <span>{t('Create Group Inside')}</span>
                        <div className='flex items-center gap-1 ml-auto'>
                          <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>G</span>
                          <span>{' + '}</span>
                          <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100'><IconArrowNarrowRight /></div>
                        </div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconServiceBelow 
                            className='h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded' 
                          />
                        </div>
                        <span>{t('Create Service Below')}</span>
                        <div className='flex items-center gap-1 ml-auto'>
                          <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>S</span>
                          <span>{' + '}</span>
                          <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100'><IconArrowNarrowDown /></div>
                        </div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconServiceInside 
                            className='h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded' 
                          />
                        </div>
                        <span>{t('Create Service Inside')}</span>
                        <div className='flex items-center gap-1 ml-auto'>
                          <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>S</span>
                          <span>{' + '}</span>
                          <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100'><IconArrowNarrowRight /></div>
                        </div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconItemBelow 
                            className='h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded' 
                          />
                        </div>
                        <span>{t('Add Item Below')}</span>
                        <div className='flex items-center gap-1 ml-auto'>
                          <span className='px-1.5 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>I</span>
                          <span>{' + '}</span>
                          <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100'><IconArrowNarrowDown /></div>
                        </div>
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconItemInside 
                            className='h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded' 
                          />
                        </div>
                        <span>{t('Add Item Inside')}</span>
                        <div className='flex items-center gap-1 ml-auto'>
                          <span className='px-1.5 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>I</span>
                          <span>{' + '}</span>
                          <div className='border-[1px] rounded-md border-cgray-200 bg-cgray-100'><IconArrowNarrowRight /></div>
                        </div>
                      </div>
                      <div className='py-2 w-full flex items-center justify-between border-b-[1px] border-cgray-300'>
                        <div className='flex gap-4 items-center'>
                          <div className='w-8 flex justify-center shrink-0'>
                            <IconCopy className='w-5 h-5 font-bold' />
                          </div>
                          <span>{t('Copy Item')}</span>
                        </div>
                        {!isMacOs ? (
                          <div className='flex items-center gap-1'>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Ctrl</span>
                            <span>{' + '}</span>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>C</span>
                          </div>
                        ) : (
                          <div className='flex items-center gap-1'>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Mod</span>
                            <span>{' + '}</span>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>C</span>
                          </div>
                        )}
                      </div>
                      <div className='py-2 w-full flex items-center justify-between border-b-[1px] border-cgray-300'>
                        <div className='flex gap-4 items-center'>
                          <div className='w-8 flex justify-center shrink-0'>
                            <IconScissors className='w-5 h-5 font-bold' />
                          </div>
                          <span>{t('Cut Item')}</span>
                        </div>
                        {!isMacOs ? (
                          <div className='flex items-center gap-1'>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Ctrl</span>
                            <span>{' + '}</span>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>X</span>
                          </div>
                        ) : (
                          <div className='flex items-center gap-1'>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Cmd</span>
                            <span>{' + '}</span>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>X</span>
                          </div>
                        )}
                      </div>
                      <div className='py-2 w-full flex items-center justify-between border-b-[1px] border-cgray-300'>
                        <div className='flex gap-4 items-center'>
                          <div className='w-8 flex items-center justify-center shrink-0 relative'>
                            <IconCopy className='w-6 h-6 font-bold' />
                            <IconArrowDown 
                              className="h-3 w-3 absolute bottom-1 right-2 cursor-pointer" 
                              stroke={3}
                            />
                          </div>
                          <span>{t('Paste below')}</span>
                        </div>
                        {!isMacOs ? (
                          <div className='flex items-center gap-1'>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Ctrl</span>
                            <span>{' + '}</span>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>V</span>
                          </div>
                        ) : (
                          <div className='flex items-center gap-1'>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Mod</span>
                            <span>{' + '}</span>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>V</span>
                          </div>
                        )}
                      </div>
                      <div className='py-2 w-full flex items-center justify-between border-b-[1px] border-cgray-300'>
                        <div className='flex gap-4 items-center'>
                          <div className='w-8 flex items-center justify-center shrink-0 relative'>
                            <IconCopy className='w-6 h-6 font-bold' />
                            <IconArrowRight 
                              className="h-3 w-3 absolute bottom-1 right-2 cursor-pointer" 
                              stroke={3}
                            />
                          </div>
                          <span>{t('Paste inside')}</span>
                        </div>
                        {!isMacOs ? (
                          <div className='flex items-center gap-1'>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Ctrl</span>
                            <span>{' + '}</span>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Shift</span>
                            <span>{' + '}</span>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>V</span>
                          </div>
                        ) : (
                          <div className='flex items-center gap-1'>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Mod</span>
                            <span>{' + '}</span>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Shift</span>
                            <span>{' + '}</span>
                            <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>V</span>
                          </div>
                        )}
                      </div>
                      <div className='py-2 flex gap-4 items-center border-b-[1px] border-cgray-300'>
                        <div className='w-8 flex justify-center shrink-0'>
                          <IconBackspace className='w-6 h-6 font-bold' />
                        </div>
                        <span>{t('Clear selection')}</span>
                        <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100 ml-auto'>Escape</span>
                      </div>
                      <div className='py-2 w-full flex items-center justify-between'>
                        <div className='flex gap-4 items-center'>
                          <div className='w-8 flex justify-center shrink-0'>
                            <IconTrash className='w-6 h-6 font-bold' />
                          </div>
                          <span>{t('Delete Item')}</span>
                        </div>
                        {!isMacOs ? (
                          <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Delete</span>
                        ) : (
                          <span className='px-1 border-[1px] rounded-md border-cgray-200 bg-cgray-100'>Backspace</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
