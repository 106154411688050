import Tippy from '@tippyjs/react';
import React, { CSSProperties, forwardRef, useState } from 'react';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, getListRowStyles } from '../../../../../helpers/utils';
import { setSelectedItem } from '../../../../../redux/quotationSlice';
import { Handle } from '../../../../shared/dnd/Item';
import styles from '../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../shared/dnd/types';
import WrapperRowProduct from './WrapperRowProduct';

interface Props {
  clone?: boolean;
  indicator?: boolean;
  wrapperRef?(node: HTMLLIElement): void;
  ghost?: boolean;
  disableSelection?: boolean;
  disableInteraction?: boolean;
  depth: number;
  handleProps?: object;
  indentationWidth: number;
  item: FlattenedItem;
  style: CSSProperties,
}

const DraggedRowProduct = forwardRef<HTMLDivElement, Props>(
  (
    {
      style, clone, depth, indentationWidth, indicator, item,
      wrapperRef, ghost, disableSelection, disableInteraction, handleProps,
    },
    ref,
  ) => {
    const [showDetails, setShowDetails] = useState(false);

    const copiedItems = useAppSelector(state => state.quotation.copiedItems);
    const selectedItem = useAppSelector(state => state.quotation.selectedItem);
    const selectedItems = useAppSelector(state => state.quotation.selectedItems);
    const itemsToCut = useAppSelector(state => state.quotation.itemsToCut);
    const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
    const dispatch = useAppDispatch();

    const selectTreeItem = () => {
      dispatch(setSelectedItem(item));
    };

    const productPropsWrapper = {
      style, clone, depth, indentationWidth, indicator, ref,
      wrapperRef, ghost, disableSelection, disableInteraction,
    };

    return (
      <WrapperRowProduct {...productPropsWrapper}>
        <>
          <div
            onClick={selectTreeItem}
            className={classNames(styles.TreeItem)}
            style={getListRowStyles(style, item, copiedItems, selectedItem, itemsToCut, showDetails, selectedItems) as React.CSSProperties}
          >
            <div
              className='flex justify-end'
              style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, depth)}px` }}
            >
            </div>
            <div className='w-14 py-3.5 flex justify-between'>
              <TreeItemIcon
                item={item}
                setShowDetails={setShowDetails}
                showDetails={showDetails}
              />
              <Handle {...handleProps} />
            </div>
            <div className="w-12">{item.product?.supplierLabel}</div>
            <div className="w-32">{item.product?.externalId}</div>
            <Tippy content={item?.product?.description} disabled={!item?.product?.description || item?.product?.description.length < 60} placement="top">
              <div className="w-96 3xl:w-[450px] 4xl:w-[800px] truncate">{item?.product?.description}</div>
            </Tippy>
          </div>
        </>
      </WrapperRowProduct>
    );
  },
);

export default DraggedRowProduct;