import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../../helpers/utils';
import LoadingIndicator from '../../../../layout/LoadingIndicator';

type Props = {
  width?: string,
  handleSubmit: (value: string | number) => void,
  defaultValue: string | number,
  loading?: boolean,
};

export default function ContextMenuInput({
  width = 'w-28',
  handleSubmit,
  defaultValue,
  loading = false,
}: Props) {
  const [value, setValue] = useState(defaultValue);
  const { t } = useTranslation();
  
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !loading) {
      handleSubmit(value);
    }
  };
  
  return (
    <div className='p-1 flex gap-2 relative'>
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={loading}
        className={classNames(
          width,
          'px-2 border-b border-cblue-500 rounded-t ring-0 outline-none bg-cgray-200 truncate',
          loading && 'opacity-50',
        )}
      />
      {loading ? (
        <div className="w-[52px] flex justify-center">
          <LoadingIndicator size="sm" />
        </div>
      ) : (
        <button 
          onClick={() => handleSubmit(value)} 
          className="bg-cblue-500 text-white px-2 py-1 rounded hover:bg-cblue-600"
        >
          {t('Apply')}
        </button>
      )}
    </div>
  );
}