import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_ITEMS_QUANTITY } from '../../../../../api/mutations/quotations/item';
import { SelectedColumnName } from '../../../../../constants';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, handleInputKeyDown } from '../../../../../helpers/utils';
import { setSelectedColumn } from '../../../../../redux/quotationSlice';

interface Props {
  quantity: number | undefined;
  id: ID;
  refetchQueries: InternalRefetchQueriesInclude,
  label?: boolean,
}

interface FormValues {
  items: [ID],
  quantity: number,
  quotation: ID,
}

export default function QuantityInput(props: Props) {
  const { quantity, id, refetchQueries, label } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { quotationId } = useParams();
  const { t } = useTranslation();
  const fieldName = 'quantity';
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [isClickedTwice, setIsClickedTwice] = useState(false);
  const selectedColumn = useAppSelector(state => state.quotation.selectedColumn);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const dispatch = useAppDispatch();
  const validationSchema = yup.object({
    [fieldName]: yup.number()
      .positive(t('Number must be positive'))
      .required(t('Required')),
  });


  const initialValues: FormValues = {
    quantity: quantity as number,
    items: [id],
    quotation: quotationId as ID,
  };

  const { handleChange, handleSubmit, values, handleBlur, isError, setFieldValue } = useQuotationItemInputHandler({
    mutation: UPDATE_ITEMS_QUANTITY,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  // For vertical moving
  useEffect(() => {
    if (selectedColumn === SelectedColumnName.QUANTITY && selectedItem?.id === id) {
      inputRef.current?.focus();
    }
  }, [selectedItem, selectedColumn, id]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    handleInputKeyDown({
      e,
      isClickedTwice,
      isClickedOnce,
      inputFocused,
      inputRef,
      handleSubmit,
      handleInputBlur,
      setIsClickedTwice,
      setFieldValue,
      fieldName,
      originalValue: quantity,
      dispatch,
    });
  };

  const handleClick = () => {
    if (inputRef.current) {
      if (isClickedOnce && !isClickedTwice) {
        setIsClickedTwice(true);
      } else if (!isClickedOnce) {
        inputRef.current.focus();
        setInputFocused(true);
        setIsClickedOnce(true);
        dispatch(setSelectedColumn(SelectedColumnName.QUANTITY));
      }
    }
  };

  const handleInputBlur = () => {
    handleBlur();
    setIsClickedTwice(false);
    setIsClickedOnce(false);
    setInputFocused(false);
  };

  return (
    <div
    className={classNames(inputFocused && 'bg-cgray-200 border-cblue-500', isError && 'border-cred-500', 'border-b  relative h-full flex items-center justify-center rounded-t bg-cgray-100 hover:bg-cgray-200 cursor-text group')}
      onClick={handleClick}
    >
      {label && (
        <label
          htmlFor={fieldName}
          className=" absolute top-0 left-2 text-2xs text-cgray-400 cursor-text"
          onClick={handleClick}
        >
          {t('Qty')}
        </label>
      )}
      <input
        name={fieldName}
        value={values[fieldName]}
        onChange={handleChange}
        className="px-0.5 w-11 h-10 flex justify-end items-end rounded-t bg-cgray-100 group-hover:bg-cgray-200 focus:bg-cgray-200 focus:outline-none focus:border-none text-sm text-right whitespace-nowrap overflow-hidden overflow-ellipsis"
        ref={inputRef}
        readOnly={!isClickedTwice}
        onFocus={() => {
          setInputFocused(true);
          dispatch(setSelectedColumn(SelectedColumnName.QUANTITY));
        }}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
