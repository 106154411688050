import { IconChevronDown } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import React, { CSSProperties, forwardRef, useEffect, useState } from 'react';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../helpers/utils';
import { setSelectedItem, unsetSelectedItem } from '../../../../../../redux/quotationSlice';
import ROGroupDetails from '../../../../../quotation/list/row/details/groupDetails/ROGroupDetails';
import { Handle } from '../../../../../shared/dnd/Item';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';

export interface Props {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  item: FlattenedItem;
  ghost?: boolean;
  handleProps?: object;
  indentationWidth: number;
  indicator?: boolean;
  onCollapse?(): void;
  wrapperRef?(node: HTMLLIElement): void;
  style: CSSProperties;
  depth: number;
}

export const GroupItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      style,
      item,
      wrapperRef,
      depth,
      ...props
    },
    ref,
  ) => {

    const [showDetails, setShowDetails] = useState(false);

    const copiedItems = useAppSelector(state => state.quotation.copiedItems);
    const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
    const selectedItem = useAppSelector(state => state.quotation.selectedItem);
    const dispatch = useAppDispatch();

    const selectTreeItem = () => {
      dispatch(setSelectedItem(item));
    };

    useEffect(() => {
      if (copiedItems?.length) {
        dispatch(unsetSelectedItem());
      }
    }, [dispatch, copiedItems]);

    const actualDepth = ghost ? depth : item.depth;

    return (
      <>
        <li
          className={classNames(
            styles.Wrapper,
            clone && styles.clone,
            ghost && styles.ghost,
            indicator && styles.indicator,
            disableSelection && styles.disableSelection,
            disableInteraction && styles.disableInteraction,
          )}
          ref={wrapperRef}
          style={
            {
              '--spacing': `${indentationWidth * actualDepth}px`,
            } as React.CSSProperties
          }
          {...props}
        >
          <div
            onClick={selectTreeItem}
            className={classNames(styles.TreeItem)}
            ref={ref}
            style={
              {
                ...style,
                '--bgColor': (copiedItems?.find(i => i.id === item.id))
                  ? '#CADDEB'
                  : item.id === selectedItem?.id
                    ? '#E4EDF5'
                    : '#fff',
                '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
                '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
                '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
                '--justifyContent': 'start',
              } as React.CSSProperties
            }
          >
            <div
              className={classNames('flex', onCollapse ? 'justify-between' : 'justify-end')}
              style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, item.depth)}px` }}
            >
              {onCollapse && (
                <div onClick={onCollapse}>
                  <IconChevronDown
                    className={classNames(
                      styles.Collapse,
                      collapsed && styles.collapsed,
                      collapsed ? '-rotate-90' : 'rotate-0',
                      'transition-all duration-200 ease-in-out cursor-pointer ml-0 text-cblue-500',
                    )}
                  />
                </div>
              )}
            </div>
            {item.group?.isProductionUnit && item.group?.productionStatus && (
              <div
                className="absolute z-10 top-0 left-10 w-6 rotate-180 font-medium text-white overflow-hidden whitespace-nowrap h-14 shadow-lg rounded-tr rounded-tl"
                style={{
                  writingMode: 'vertical-rl',
                  backgroundColor: item.group.productionStatus.color,
                }}
              >
                <Tippy
                  content={item.group.productionStatus.title}
                  placement='bottom'
                  disabled={item.group.productionStatus.title.length < 7}
                >
                  <span className='truncate cursor-default' style={{ marginTop: '0.5rem' }}>
                    {item.group?.productionStatus.title}
                  </span>
                </Tippy>
              </div>
            )}
            <div className='w-14 py-3.5 flex justify-between'>
              <TreeItemIcon
                item={item}
                setShowDetails={setShowDetails}
                showDetails={showDetails}
              />
              <Handle {...handleProps} />
            </div>
            {
              clone ? (
                <>
                  <div className="w-12"></div>
                  <div className="w-32"></div>
                  <Tippy content={item.group?.description} disabled={!!item.group?.description && item.group?.description.length < 20} placement="top">
                    <div className="w-[300px] 3xl:w-[360px] 4xl:w-[440px] truncate">{item?.group?.description}</div>
                  </Tippy>
                  <div className="w-8">
                    {childCount && childCount > 1 ? (
                      <span className={styles.Count}>{childCount}</span>
                    ) : null}
                  </div>
                </>
              ) : (
                <>
                  <div className="w-12"></div>
                  <div className="w-32"></div>
                  <Tippy content={item.group?.description} disabled={!!item.group?.description && item.group?.description.length < 20} placement="top">
                    <div className="w-[300px] 3xl:w-[360px] 4xl:w-[440px] truncate">{item?.group?.description}</div>
                  </Tippy>
                </>
              )
            }
          </div>
          {showDetails && item.group && (
            <ROGroupDetails
              groupId={item.group.id}
              isGroupQuotation={item.group?.__typename === 'QuotationGroupType'}   
              indentationWidth={indentationWidth}
              depth={item.depth}
              maximumUncollapsedDepth={maximumQuotationUncollapsedDepth} />
          )}
        </li>
      </>
    );
  },
);
