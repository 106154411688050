import { useQuery } from '@apollo/client';
import { arrayToTree, Item, TreeItem } from 'performant-array-to-tree';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ItemTypeChoices, QuotationVersionTypeChoices } from '../../../__generated__/graphql';
import {
  QUOTATION_QUERY,
} from '../../../api/queries/quotations/quotation';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../layout';
import { setQuotationItemsTreeLength, setIsQuotationReadOnly } from '../../../redux/quotationSlice';
import type { FlattenedItem } from '../../shared/dnd/types';
import QuotationSummary from '../summary/QuotationSummary';
import ROQuotationSummary from '../summary/ROQuotationSummary';
import ListHeader from './header/ListHeader';
import NoGroupRecordsArea from './NoGroupRecordsArea';
import QuotationList from './QuotationList';

export default function QuotationListWrapper() {
  const { quotationId } = useParams();
  const dispatch = useAppDispatch();
  const [itemsTree, setItemsTree] = useState<TreeItem[]>([]);
  const showFullSummary = useAppSelector(state => state.quotation.showFullSummary);
  const {
    data: quotationData,
    loading: quotationIsLoading,
  } = useQuery(QUOTATION_QUERY, {
    variables: {
      quotation: quotationId as string,
    },
    skip: !quotationId,
    fetchPolicy: 'network-only',
  });

  const isQuotationReadOnly = quotationData?.quotation?.response?.versionType === QuotationVersionTypeChoices.Readonly;
  const indentationWidth = 25;

  // useEffect to avoid unnecessary QuotationList rerender with defaultItems "collapsed" property reset to undefined
  useEffect(() => {
    const quotationItems = quotationData
      && quotationData.quotation
      && quotationData.quotation.response
      && quotationData.quotation.response.items || [];

    const preparedItems = quotationItems.map(item => {
      return {
        ...item,
        canHaveChildren: item?.itemType === ItemTypeChoices.Group,
      };
    });
    if (quotationData) {
      setItemsTree(arrayToTree(preparedItems as Item[], { dataField: null }));
    }
  }, [dispatch, quotationData]);

  useEffect(() => {
    if (itemsTree) {
      dispatch(setQuotationItemsTreeLength(itemsTree.length));
    }
  }, [itemsTree, dispatch]);

  useEffect(() => {
    if (isQuotationReadOnly) {
      dispatch(setIsQuotationReadOnly(true));
    } else {
      dispatch(setIsQuotationReadOnly(false));
    }
  }, [dispatch, isQuotationReadOnly]);

  if (quotationIsLoading) {
    return (
      <LoadingIndicator className="w-full h-96 flex justify-center items-center" />
    );
  } else if (itemsTree) {
    return (
      <div className="pb-52">
        {quotationData?.quotation?.response?.items
          && quotationData.quotation.response.items.length > 1 
          && showFullSummary &&
          (
            <>
              {isQuotationReadOnly ? (
                <ROQuotationSummary
                  items={itemsTree as FlattenedItem[]}
                  totalSalesPriceLabel={quotationData?.quotation?.response?.totalSalesPriceLabel as string}
                />
              ) : (
                <QuotationSummary
                  items={itemsTree as FlattenedItem[]}
                  totalSalesPriceLabel={quotationData?.quotation?.response?.totalSalesPriceLabel as string}
                />
              )}

            </>
          )
        }
        <ListHeader indentationWidth={indentationWidth} />
        <QuotationList
          defaultItems={itemsTree as FlattenedItem[]}
          indentationWidth={indentationWidth}
        />

        {!itemsTree.length && (
          <div className="flex flex-col gap-4">
            <NoGroupRecordsArea />
          </div>
        )}
      </div>
    );
  }
  return (<></>);
}
