import {
  IconBackspace,
  IconCopy,
  IconIdOff,
  IconListDetails,
  IconScissors,
  IconTrash,
} from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { IconGroupBelow, IconItemBelow, IconServiceBelow, IconProductBelow } from '../../../../layout/icons';
import { discardAlert } from '../../../../redux/alertSlice';
import { openCreateGroupModal, openCreateServiceModal, setCopiedItems, setCreateNewItemInside, setIsCreateItemOverlayOpen, setItemsToCut, setItemToSinglePasteBelow, setNewProductOrder, setShowDeleteModal, unsetCopiedItems, unsetItemsToCut } from '../../../../redux/quotationSlice';
import { setDestinationProjectId, setDestinationQuotationId, setRedirectToQuotation } from '../../../../redux/searchSlice';
import { FlattenedItem } from '../../../shared/dnd/types';
import { PasteBelowQuotationItem } from '../actions';

interface Props {
  item: FlattenedItem;
  showDetails: boolean,
  setShowDetails: Dispatch<SetStateAction<boolean>>,
  isItemHoveredId: string,
  setIsItemHoveredId: (id: string | null) => void,
}

export default function HoveredContextMenu(props: Props) {
  const { item, showDetails, setShowDetails, isItemHoveredId, setIsItemHoveredId } = props;
  const { projectId, quotationId } = useParams();
  const copiedItems = useAppSelector(state => state.quotation.copiedItems);
  const itemsToCut = useAppSelector(state => state.quotation.itemsToCut);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const isQuotationReadOnly = useAppSelector(state => state.quotation.isQuotationReadOnly);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();


  const showDetailsHandleClick = (isShow: boolean) => {
    if (setShowDetails) setShowDetails(!isShow);
  };

  const copyQuotationItemHandleClick = () => {
    dispatch(setCopiedItems([item]));
    dispatch(unsetItemsToCut());
  };

  const cutQuotationItemHandleClick = () => {
    dispatch(setItemsToCut([item]));
    dispatch(unsetCopiedItems());
  };

  const handleClearSelection = () => {
    dispatch(unsetCopiedItems());
    dispatch(unsetItemsToCut());
  };

  const handleAddItem = () => {
    if (projectId && quotationId) {
      dispatch(setRedirectToQuotation(true));
      dispatch(setDestinationQuotationId(quotationId));
      dispatch(setDestinationProjectId(projectId));
      dispatch(setItemToSinglePasteBelow(item));
      navigate('/search/single/');
    }
  };

  const createProduct = () => {
    if (projectId && quotationId) {
      dispatch(discardAlert());
      dispatch(setCreateNewItemInside(false));
      dispatch(setNewProductOrder((item?.order as number) + 1));
      dispatch(setDestinationQuotationId(quotationId));
      dispatch(setDestinationProjectId(projectId));
      dispatch(setItemToSinglePasteBelow(item));
      dispatch(setIsCreateItemOverlayOpen(true));
    }
  };

  const hoveredStyles = () => {
    if (copiedItems?.find(i => i.id === item.id)) {
      return 'bg-cblue-300';
    }
    if (selectedItem && selectedItem.id === item.id) {
      return 'bg-cblue-200';
    }
    return 'bg-white';
  };

  return (
    <div 
      className={`absolute right-0 inset-y-0 mr-4 flex items-center justify-center gap-3 ${hoveredStyles()}`}
      onMouseLeave={() => {if (isItemHoveredId !== item.id) setIsItemHoveredId(null);}}
    >
      {showDetails ? (
        <>
          <Tippy content={t('Hide details')} placement="top" >
            <IconIdOff className="h-5 w-5 hover:bg-cblue-200 cursor-pointer" onClick={() => showDetailsHandleClick(showDetails)} />
          </Tippy>
        </>
      ) : (
        <>
          <Tippy content={t('Show details')} placement="top" >
            <IconListDetails className="h-5 w-5 hover:bg-cblue-200 cursor-pointer" onClick={() => showDetailsHandleClick(showDetails)} />
          </Tippy>
        </>
        
      )}
      {!isQuotationReadOnly && (
        <>
          <Tippy content={t('Create Group Below')} placement="top" >
            <div>
              <IconGroupBelow 
                className="h-5 w-7 text-xs bg-transparent text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                onClick={() => {
                  dispatch(discardAlert());
                  dispatch(setCreateNewItemInside(false));
                  dispatch(openCreateGroupModal({ targetOrder: (item?.order as number) + 1 }));
                }}
              />
            </div>
          </Tippy>
          <Tippy content={t('Create Service Below')} placement="top" >
            <div>
              <IconServiceBelow 
                className="h-5 w-7 text-xs bg-transparent text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                onClick={() =>{
                  dispatch(discardAlert());
                  dispatch(setCreateNewItemInside(false));
                  dispatch(openCreateServiceModal( { targetOrder: (item?.order as number) + 1 }));
                }}
              /> 
            </div>
          </Tippy>
          <Tippy content={t('Add Item Below')} placement="top" >
            <div>
              <IconItemBelow 
              className="h-5 w-7 text-xs bg-transparent text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
              onClick={() => {
                dispatch(discardAlert());
                dispatch(setCreateNewItemInside(false));
                handleAddItem();
              }}
            />
            </div>
          </Tippy>
          <Tippy content={t('Create Product Below')} placement="top" >
            <div>
              <IconProductBelow 
                className="h-5 w-7 text-xs bg-transparent text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                onClick={createProduct}
              />
            </div>
          </Tippy>

          {!copiedItems?.find(i => i.id === item.id) && (
            <>
              <Tippy content={t('Copy Item')} placement="top" >
                <IconCopy className="h-5 w-5 cursor-pointer hover:bg-cblue-200" onClick={copyQuotationItemHandleClick} />
              </Tippy>
            </>
          ) }
          {!itemsToCut?.find(i => i.id === item.id) && (
            <>
              <Tippy content={t('Cut Item')} placement="top" >
                <IconScissors className="h-5 w-5 cursor-pointer hover:bg-cblue-200" onClick={cutQuotationItemHandleClick} />
              </Tippy>
            </>
          ) }
          {(!!copiedItems?.length || !!itemsToCut?.length) && (
            <>
              <Tippy content={t('Paste below')} placement="top" >
                <div>
                  <PasteBelowQuotationItem item={item} />
                </div>
              </Tippy>
            </>
          ) }
          {
            (copiedItems?.find(i => i.id === item.id) || itemsToCut?.find(i => i.id === item.id))
            && (
            <>
              <Tippy content={t('Clear selection')} placement="top" >
                <IconBackspace className="h-5 w-5 cursor-pointer hover:bg-cblue-200" onClick={handleClearSelection} />
              </Tippy>
            </>
            )
          }
          <Tippy content={t('Remove Item')} placement="top" >
            <div>
              <IconTrash className="h-5 w-5 cursor-pointer hover:bg-cblue-200" onClick={() => dispatch(setShowDeleteModal(true))} />
            </div>
          </Tippy>
        </>
      )}
    </div>
  );
}
