import type { InternalRefetchQueriesInclude } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_QUOTATION_ITEM_POSITION } from '../../../../../api/mutations/quotations/item';
import { SelectedColumnName } from '../../../../../constants';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, handleInputKeyDown } from '../../../../../helpers/utils';
import { setSelectedColumn } from '../../../../../redux/quotationSlice';

interface Props {
  position: string | undefined;
  id: ID;
  refetchQueries: InternalRefetchQueriesInclude,
}

interface FormValues {
  position: string | undefined,
  item: ID,
  quotation: ID,
}

export default function PositionInput(props: Props) {
  const { position, id, refetchQueries } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { quotationId } = useParams();
  const { t } = useTranslation();
  const fieldName = 'position';
  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [isClickedTwice, setIsClickedTwice] = useState(false);
  const selectedColumn = useAppSelector(state => state.quotation.selectedColumn);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const dispatch = useAppDispatch();
  
  const validationSchema = yup.object({
    [fieldName]: yup.string()
      .matches(
        /^[0-9]+(\.[0-9]+)*$/, 
        t('Position should contain only positive numbers and dots'),
      )
      .required(t('Required')),
  });


  const initialValues: FormValues = {
    position: position,
    item: id,
    quotation: quotationId as ID,
  };

  const { handleChange, handleSubmit, values, handleBlur, isError, setFieldValue } = useQuotationItemInputHandler({
    mutation: UPDATE_QUOTATION_ITEM_POSITION,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  // For vertical moving
  useEffect(() => {
    if (selectedColumn === SelectedColumnName.POSITION && selectedItem?.id === id) {
      inputRef.current?.focus();
    }
  }, [selectedItem, selectedColumn, id]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    handleInputKeyDown({
      e,
      isClickedTwice,
      isClickedOnce,
      inputFocused,
      inputRef,
      handleSubmit,
      handleInputBlur,
      setIsClickedTwice,
      setFieldValue,
      fieldName,
      originalValue: position,
      dispatch,
    });
  };

  const handleClick = () => {
    if (inputRef.current) {
      if (isClickedOnce && !isClickedTwice) {
        setIsClickedTwice(true);
      } else if (!isClickedOnce) {
        inputRef.current.focus();
        setInputFocused(true);
        setIsClickedOnce(true);
        dispatch(setSelectedColumn(SelectedColumnName.POSITION));
      }
    }
  };

  const handleInputBlur = () => {
    handleBlur();
    setIsClickedTwice(false);
    setIsClickedOnce(false);
    setInputFocused(false);
  };

  return (
    <Tippy content={values[fieldName]} placement="right" disabled={values[fieldName].length < 10}>
      <div
        className={classNames(inputFocused && 'bg-cgray-200 border-cblue-500', isError && 'border-cred-500', 'border-b py-px h-full flex items-center justify-between rounded-t bg-cgray-100 hover:bg-cgray-200 cursor-text group')}
        onClick={handleClick}
      >
        <input
          name={fieldName}
          value={values[fieldName]}
          onChange={handleChange}
          className="px-0.5 w-20 h-10 flex items-center justify-end rounded-t bg-cgray-100 group-hover:bg-cgray-200 focus:bg-cgray-200 focus:outline-none focus:border-none text-sm text-right text-ellipsis"
          style={{ direction: inputFocused ? 'ltr' : 'rtl' }}
          ref={inputRef}
          readOnly={!isClickedTwice}
          onFocus={() => {
            setInputFocused(true);
            dispatch(setSelectedColumn(SelectedColumnName.POSITION));
          }}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
        />
      </div>
    </Tippy>
  );
}
