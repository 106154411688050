import { useQuery } from '@apollo/client';
import { IconEqual, IconPlus, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WageType } from '../../../../../__generated__/graphql';
import { SERVICE_ESTIMATE_DETAILS_QUERY } from '../../../../../api/queries/quotations/details';
import { QUOTATION_QUERY } from '../../../../../api/queries/quotations/quotation';
import { SERVICE_DETAILS_ROW_HEIGHT } from '../../../../../constants';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { useAppSelector } from '../../../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../../../layout';
import { ServiceTimeInput, ServiceWageSelect, ProfitSurchargeInput } from '../../fields/service';
import AddArrowWrapper from './AddArrowWrapper';


interface Props {
  serviceId: ID,
  indentationWidth: number;
  depth: number;
}

export default function EstimateServiceDetails(props: Props) {
  const { indentationWidth, depth, serviceId } = props;
  const { t } = useTranslation();
  const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
  const { quotationId } = useParams();

  const {
    data: estimateData,
    loading: estimateLoading,
  } = useQuery(SERVICE_ESTIMATE_DETAILS_QUERY, {
    variables: {
      service: serviceId as string,
    },
  });

  if (estimateLoading) {
    return (
      <div className='p-2.5 pr-5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <LoadingIndicator className="w-full flex h-56 justify-center items-center" />
      </div>
    );
  } else if (!estimateLoading && estimateData && estimateData.serviceEstimateDetails && estimateData.serviceEstimateDetails.response) {
    const details = estimateData.serviceEstimateDetails.response;

    return (
      <div 
        id="styled-scroll" 
        className='p-2.5 flex border border-t-0 rounded-bl rounded-br border-cgray-300 text-xs 2xl:text-sm text-left text-cgray-600 overflow-x-auto z-20 bg-white overflow-y-auto'
        style={{ height: `${SERVICE_DETAILS_ROW_HEIGHT}px` }}
      >
        <div
          className="flex justify-end"
          style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, depth)}px` }}
        ></div>

        <AddArrowWrapper nestingLevel={2}>
          <div className='relative h-full grid grid-rows-10 border-[1px] gap-5 rounded rounded-tl border-cgray-400 grow'>
            <div className='row-span-5 flex flex-row h-full items-center'>
              <AddArrowWrapper nestingLevel={3}>
                <div className='relative h-full grid grid-rows-10 gap-3 border-b-[1px] border-r-[1px] rounded border-cgray-400 grow'>
                  <div className='row-span-5 flex flex-row h-full items-center'>
                    <div className='relative h-full grid grid-rows-10 border-r-[1px] rounded border-cgray-400 grow'>
                      <div className='row-span-5 flex flex-row h-full items-center'>
                        <AddArrowWrapper nestingLevel={4}>
                          <div className='h-full border-b-[1px] border-r-[1px] rounded border-cgray-400 flex flex-row justify-between items-center grow'>
                            <div className=" w-28 h-full">
                              <ServiceWageSelect
                                serviceWage={details.serviceWage as WageType}
                                id={serviceId}
                                quotationId={quotationId as ID}
                                refetchQueries={
                                  [
                                    {
                                      query: QUOTATION_QUERY,
                                      variables: {
                                        quotation: quotationId as string,
                                      },
                                    },
                                    {
                                      query: SERVICE_ESTIMATE_DETAILS_QUERY,
                                      variables: {
                                        service: serviceId as string,
                                      },
                                    },
                                  ]
                                }
                                label={t('Service Wage')}
                              />
                            </div>
                            <div className='h-full flex gap-1 items-center'>
                              <IconX className='w-4 h-4' />
                              <div className='w-28 h-full'>
                                <ServiceTimeInput
                                  serviceTime={details.serviceTime}
                                  id={serviceId}
                                  refetchQueries={
                                    [
                                      {
                                        query: QUOTATION_QUERY,
                                        variables: {
                                          quotation: quotationId as string,
                                        },
                                      },
                                      {
                                        query: SERVICE_ESTIMATE_DETAILS_QUERY,
                                        variables: {
                                          service: serviceId as string,
                                        },
                                      },
                                    ]
                                  }
                                  label={t('Service Time')}
                                />
                              </div>
                            </div>
                          </div>
                        </AddArrowWrapper>
                        <div className='pl-1 pr-2 w-96 flex justify-between'>
                          <div className='font-bold'>{t('Service Labour Cost')}</div>
                          <p className='text-cgray-400'>{details.serviceLabourCostLabel}</p>
                        </div>
                      </div>
                      <div className=' -z-10 absolute top-0 right-0 h-full w-full flex justify-end pr-4 items-center'>
                        <IconPlus className='w-4 h-4 ' />
                      </div>
                      <div className='row-span-5 flex flex-row h-full  items-center'>
                        <AddArrowWrapper nestingLevel={4}>
                          <div className='pl-1 pr-2 h-full border-t-[1px] border-r-[1px] rounded border-cgray-400 text-left grow'>
                            <div className='font-bold flex items-center'>
                              <div
                              >
                                {t('Service Labour Cost')}
                              </div>
                              <IconX className='w-4 h-4 mx-1' />
                              {t('Manufacturing Overhead Rate')}
                            </div>
                            <p className='text-cgray-400 flex items-center'>
                              {details.serviceLabourCostLabel}
                              <IconX className='w-4 h-4 mx-1' />
                              {details.manufacturingOverheadRate?.label}
                            </p>
                          </div>
                        </AddArrowWrapper>
                        <div className='pl-1 pr-2 w-96 flex justify-between'>
                          <div className='font-bold'>{t('Manufacturing Overhead')}</div>
                          <p className='text-cgray-400'>{details.manufacturingOverheadLabel}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AddArrowWrapper>
              <div className='pl-1 pr-2 w-60 2xl:w-72 flex justify-between'>
                <div className='font-bold'>{t('Manufacturing Cost')}</div>
                <p className='text-cgray-400'>{details.manufacturingCostLabel}</p>
              </div>
            </div>
            <div className=' -z-10 absolute top-0 right-0 h-full w-full flex justify-end pr-4 items-center'>
              <IconPlus className='w-6 h-6 ' />
            </div>

            <div className='row-span-5 flex flex-row items-center h-full'>
              <AddArrowWrapper nestingLevel={3}>
                <div className='relative h-full grid grid-rows-10 border-t-[1px] border-r-[1px] rounded border-cgray-400 grow'>
                  <div className='row-span-5 flex flex-row h-full items-center'>
                    <AddArrowWrapper nestingLevel={4}>
                      <div className='relative h-full flex flex-col border-b-[1px] border-r-[1px] rounded border-cgray-400 grow'>
                        <div className='flex flex-row  items-center'>
                          <div className='pl-1 pr-2 h-full border-r-[1px] rounded border-cgray-400 text-left  grow'>
                            <div className='font-bold flex items-center'>
                              <div
                              >
                                {t('Manufacturing Cost')}
                              </div>
                              <IconX className='w-4 h-4 mx-1' />
                              {t('Sales Overhead Rate')}
                            </div>
                            <p className='text-cgray-400 flex items-center'>
                              {details.manufacturingCostLabel}
                              <IconX className='w-4 h-4 mx-1' />
                              {details.salesOverheadRate?.label}
                            </p>
                          </div>
                        </div>
                      </div>
                    </AddArrowWrapper>
                    <div className='pl-1 pr-2 w-96 flex justify-between'>
                      <div className='font-bold'>{t('Sales Cost')}</div>
                      <p className='text-cgray-400'>{details.salesCostLabel}</p>
                    </div>
                  </div>
                  <div className=' -z-10 absolute top-0 right-0 h-full w-full flex justify-end pr-4 items-center'>
                    <IconPlus className='w-5 h-5 ' />
                  </div>
                  <div className='row-span-5 flex flex-row items-center'>
                    <AddArrowWrapper nestingLevel={4}>
                      <div className='relative h-full flex flex-col gap-4 border-t-[1px] border-r-[1px] rounded border-cgray-400 grow'>
                        <div className='pl-1 pr-2 h-full border-r-[1px] rounded border-cgray-400 text-left  grow'>
                          <div className='font-bold flex items-center'>
                            <div
                            >
                              {t('Manufacturing Cost')}
                            </div>
                            <IconX className='w-4 h-4 mx-1' />
                            {t('Administrative Overhead Rate')}
                          </div>
                          <p className='text-cgray-400 flex items-center'>
                            {details.manufacturingCostLabel}
                            <IconX className='w-4 h-4 mx-1' />
                            {details.administrativeOverheadRate?.label}
                          </p>
                        </div>
                      </div>
                    </AddArrowWrapper>
                    <div className='pl-1 pr-2 w-96 flex justify-between'>
                      <div className='font-bold'>{t('Administrative Costs')}</div>
                      <p className='text-cgray-400'>{details.administrativeCostsLabel}</p>
                    </div>
                  </div>
                </div>
              </AddArrowWrapper>
              <div className='pl-1 pr-2 w-60 2xl:w-72 flex justify-between'>
                <div className='font-bold'>{t('Cost Administration Sales')}</div>
                <p className='text-cgray-400'>{details.costAdministrationSalesLabel}</p>
              </div>
            </div>
          </div>
        </AddArrowWrapper>
        <div className='-ml-4 pl-5 pb-2 w-72 2xl:w-80 flex flex-col gap-4 justify-center items-center border-[1px] rounded border-cgray-400 self-stretch'>
          <div className='pl-1 pr-2 w-full flex justify-between text-lg'>
            <div className='font-bold'>{t('Cost Per Item')}</div>
            <div className='text-cgray-400 font-bold'>{details.costPerItemLabel}</div>
          </div>
          <div className='w-full flex justify-between items-center'>
            <div className='w-28 h-full'>
              <ProfitSurchargeInput
                serviceId={serviceId}
                profitSurcharge={details.profitSurcharge}
                quotationId={quotationId as ID}
                refetchQueries={[
                  {
                    query: QUOTATION_QUERY,
                    variables: {
                      quotation: quotationId as string,
                    },
                  },
                  {
                    query: SERVICE_ESTIMATE_DETAILS_QUERY,
                    variables: {
                      service: serviceId as string,
                    },
                  },
                ]}
                label
              />
            </div>
            <div className='font-bold text-xl pr-6'><IconEqual /></div>
          </div>
          <div className='pl-1 pr-2 w-full flex justify-between text-lg'>
            <div className='font-bold'>{t('Net Sales Price/Piece')}</div>
            <div className='text-cgray-400 font-bold'>{details.netSalesPricePieceLabel}</div>
          </div>
          <div className='pl-1 pr-2 w-full flex justify-between border-t pt-2'>
            <div className='font-bold'>{t('Profit per Piece')}</div>
            <div className='text-cgray-400 font-bold'>{details.profitPerPieceLabel}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
